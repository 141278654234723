<script>
export default {
  name: 'PositionDetails',
  components: {
    Loading: () => import('@/components/general/Loading'),
    GenericSlider: () => import('@/components/general/GenericSlider')
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    placeholder: {
      type: Object,
      default: () => ({})
    },
    onFavorite: {
      type: Function,
      default: null
    },
    onReady: {
      type: Function,
      default: null
    }
  },
  data () {
    const positionDetails = { ...this.placeholder, id: this.id }
    if (!positionDetails.company) positionDetails.company = {}
    if (!positionDetails.details) positionDetails.details = ''
    if (!positionDetails.languages) positionDetails.languages = []
    if (!positionDetails.skills) positionDetails.skills = []
    if (!positionDetails.files) positionDetails.files = []
    return {
      loading: false,
      relatedCourses: [],
      relatedPositions: [],
      applyDisabled: true,
      applyText: 'global:wait',
      positionDetails
    }
  },
  methods: {
    applyToPosition () {
      const position = this.positionDetails
      const enliztme = process.env.VUE_APP_ENLIZTME_DOMAIN + (process.env.VUE_APP_ENLIZTME_PORT ? (':' + process.env.VUE_APP_ENLIZTME_PORT) : '')
      const isbra = position.company.language === 'pt'
      const form = document.createElement('form')
      form.target = 'enliztme'
      form.method = 'POST'
      form.action = `${process.env.VUE_APP_ENLIZTME_PROTOCOL}://${position.company.subdomain}.${enliztme}/${isbra ? 'vagas' : 'positions'}/${position.id}/${isbra ? 'candidatar' : 'apply'}?n=${encodeURI(position.title)}`
      const input = document.createElement('input')
      input.type = 'hidden'
      input.name = 'token'
      input.value = this.$store.getters.getUserToken
      form.appendChild(input)
      document.body.appendChild(form)
      form.submit()
      form.parentNode.removeChild(form)
      this.applyDisabled = true
      this.applyText = 'position:applied'
    },
    toggleFavorite () {
      const isFavorite = this.positionDetails.isFavorite
      this.positionDetails.isFavorite = !isFavorite
      this.$store
        .dispatch(isFavorite ? 'attemptRemoveFavoritePosition' : 'attemptSaveFavoritePosition', this.positionDetails.id)
        .then(() => {
          if (this.onFavorite) this.onFavorite(!isFavorite)
        })
        .catch((err) => {
          this.positionDetails.isFavorite = isFavorite
          if (err.code === 403 && err.error === 'not_allowed') {
            this.showNotAllowedModal = true
          } else {
            this.directToRoute()
            this.$store.dispatch('attemptSetFeedbackMsg', {
              type: 'error',
              title: this.$t('content:error.load')
            })
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleShare () {
      this.$store.dispatch('attemptSetFeedbackMsg', {
        type: 'success',
        title: this.$t('content:success.share')
      })
      navigator.clipboard.writeText(this.positionDetails.url.me)
    }
  },
  created () {
    this.loading = true
    const user = this.$store.getters.getUser
    this.$store.dispatch('attemptGetPositionDetailsById', { positionId: this.id, aggregator: this.getCompanyDomainAndWorkspace(), lang: user.language }).then(details => {
      this.loading = false
      this.positionDetails = details
      this.applyDisabled = !details || details.unavailableToCandidate || !details.allowNewApplications || details.applicationStatus
      this.applyText = 'position:apply'
      if (!details.applicationStatus) {
        if (!details.allowNewApplications) this.applyText = 'position:paused'
      } else {
        this.applyText = 'position:applied'
      }
      if (this.onReady) this.onReady(this.positionDetails)
    })
    this.$store.dispatch('attemptGetPositionRelatedData', { positionId: this.id, aggregator: this.getCompanyDomainAndWorkspace() }).then(related => {
      this.relatedCourses = related?.course || []
      this.relatedPositions = related?.position || []
    })
  }
}
</script>
<template>
  <div v-if="loading">
    <Loading />
  </div>
  <v-container v-else class="content-position-details-container">
    <div class="content-position-details--header">
      <div class="position-details-header--title">
        <v-icon class="mr-2" :color="getPrimaryColor">mdi-briefcase</v-icon>
        <h2 class="mr-2" :style="{color:getPrimaryColor}">{{ $t('application.card:position') }}</h2>
        <v-icon class="mr-1" small :color="getPrimaryColor">mdi-pound</v-icon>
        <span class="mr-1">{{ $t('discovery.details.label:details.code') }}: {{ positionDetails.code }}</span>
      </div>
      <div class="position-details-header--actions">
        <v-icon class="cursor-pointer mr-2" :color="getPrimaryColor" @click="handleShare()">mdi-share-variant</v-icon>
        <v-icon class="cursor-pointer" :color="getPrimaryColor" @click="toggleFavorite()">{{positionDetails.isFavorite ? 'mdi-heart' : 'mdi-heart-outline' }}</v-icon>
      </div>
    </div>
    <div class="content-position-details--body">
      <div class="position-details-body--header">
        <div class="position-details-body--avatar mb-4">
          <v-avatar class="mr-2" :color="getPrimaryColor" size="30">
            <img class="avatar--icon" :src="positionDetails.company.logo" :alt="positionDetails.company.name"/>
          </v-avatar>
          <p class="position-details-body-currency">{{ positionDetails.company.name }}</p>
        </div>
        <div class="position-details-body--main mb-4">
          <h5 class="position-details-body--title text-center">{{ positionDetails.title }}</h5>
          <div class="position-details-body--info">
            <div class="position-details-item mr-4">
              <v-icon class="mr-1" :color="getPrimaryColor">mdi-map-marker</v-icon>
              <span class="position-details-body-currency">{{ positionDetails.telecommute ? parseTextFormat({key: 'telecommute', text: positionDetails.telecommute}) : positionDetails.location }}</span>
            </div>
            <div class="position-details-item mr-2">
              <v-icon class="mr-1" :color="getPrimaryColor">mdi-briefcase-clock</v-icon>
              <span class="position-details-body-web">{{ positionDetails.type ? $t('discovery.modal.search:contract.type.' + positionDetails.type) : '' }}</span>
            </div>
            <div class="position-details-item">
              <v-icon class="mr-1" :color="getPrimaryColor">mdi-currency-usd</v-icon>
              <span class="position-details-body-timer">{{ formatSalary(positionDetails) }}</span>
            </div>
          </div>
        </div>
        <v-card-actions>
          <v-btn class="btn bold transform-unset mb-4" :dark="!applyDisabled" :color="getPrimaryColor" @click="applyToPosition()" :disabled="applyDisabled">
          {{ $t(applyText) }}
          </v-btn>
        </v-card-actions>
      </div>
      <div class="position-details-body--description d-flex flex-column align-center align-sm-start mb-0">
        <p class="position-details-body-title mb-2">{{ $t('global:description') }}</p>
        <p class="position-details-body-description markdown-body" v-html="this.markdownToHtml(positionDetails.details)"></p>
      </div>
      <div class="position-details-body--requirements d-flex flex-column align-center align-sm-start" v-if="positionDetails.skills.length || positionDetails.languages.length">
        <p>{{ $t('discovery.details.label:details.requirements') }}</p>
        <div class="position-details-requirements d-flex flex-column align-center align-sm-start" v-if="positionDetails.skills.length">
          <p>{{ $t('discovery.details.label:details.experiences') }}</p>
          <div class="position-details-requirements--tags">
            <div v-for="skill in positionDetails.skills" :key="skill">{{ skill }}</div>
          </div>
        </div>
        <div class="position-details-requirements d-flex flex-column align-center align-sm-start"  v-if="positionDetails.languages.length">
          <p>{{ $t('completition.criterias:languages.data') }}</p>
          <div class="position-details-requirements--tags">
            <div v-for="language in positionDetails.languages" :key="language">{{ language }}</div>
          </div>
        </div>
      </div>
      <div class="position-details-body--files d-flex flex-column align-center align-sm-start" v-if="positionDetails.files.length">
        <p>{{ $t('discovery.details.label:details.files') }}</p>
        <ul class="position-details-requirements-files d-flex flex-column align-center align-sm-start pa-0">
          <li class="d-flex align-center" v-for="file in positionDetails.files" :key="file.id">
            <v-btn text :color="getPrimaryColor" :href="file.source" class="transform-unset" target="_blank">
              <v-icon left>mdi-cloud-download</v-icon>
              {{ file.name }}
            </v-btn>
          </li>
        </ul>
      </div>
      <div class="position-details-body--features rounded-pill d-flex flex-sm-row flex-column align-center align-sm-start">
        <div class="position-details-features--item mb-3 mb-sm-0">
          <span class="position-details-features-title">{{ $t('discovery.modal.search:workplace.cities') }}</span>
          <div>
            <v-icon class="mr-1" :color="getPrimaryColor">mdi-map-marker</v-icon>
            <span>{{ positionDetails.telecommute ? parseTextFormat({key: 'telecommute', text: positionDetails.telecommute}) : positionDetails.location }}</span>
          </div>
        </div>
        <div class="position-details-features--item mb-3 mb-sm-0">
          <span class="position-details-features-title">{{ $t('discovery.details.label:details.hiringType') }}</span>
          <div>
            <v-icon class="mr-1" :color="getPrimaryColor">mdi-briefcase-clock</v-icon>
            <span>{{ positionDetails.type ? $t('discovery.modal.search:contract.type.' + positionDetails.type) : '' }}</span>
          </div>
        </div>
        <div class="position-details-features--item" v-if="positionDetails.salary">
          <span class="position-details-features-title">{{ $t('discovery.details.label:details.salary') }}</span>
          <div>
            <v-icon class="mr-1" :color="getPrimaryColor">mdi-currency-usd</v-icon>
            <span>{{ formatSalary(positionDetails) }}</span>
          </div>
        </div>
      </div>
      <v-card-actions class="position-details-body--footer">
        <v-btn class="btn bold transform-unset" dark outlined :color="getPrimaryColor" @click="handleShare()">
          {{ $t('position:share') }}
        </v-btn>
        <v-btn class="btn bold transform-unset" :dark="!applyDisabled" :color="getPrimaryColor" @click="applyToPosition()" :disabled="applyDisabled">
          {{ $t(applyText) }}
        </v-btn>
      </v-card-actions>
      <GenericSlider v-if="relatedCourses.length" :toggleCardUrl="true" :title="$tc('discovery.details.label:details.related.course')" :items="relatedCourses" type="courses" />
      <GenericSlider v-if="relatedPositions.length" :toggleCardUrl="true" :title="$tc('discovery.details.label:details.another.related.position')" :items="relatedPositions" type="positions" />
    </div>
  </v-container>
</template>
<style lang="scss">
.content-position-details-container{
  @import '../../assets/styles/markdown';
  margin-top: -20px;
  max-width: 900px;
  text-align: left;
  margin-bottom: 40px;
  .content-position-details--header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .position-details-header--title{
      display: flex;
      align-items: center;
      h2{
        font-size: 16px;
        font-weight: 800;
      }
    }
    .position-details-header--icons{
      .content-position-details-numberOfCourses {
        background: linear-gradient(0deg, rgba(98, 181, 90, 0.1), rgba(98, 181, 90, 0.1)), #FFFFFF;;
        height: 25px;
        p.data-text-chip{
          font-family: 'Lato';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          margin-bottom: 0;
        }
      }
      .content-position-details-disabledOnly {
        .v-icon {
          margin-left: 8px;
        }
        padding-left: 5px;
        background: linear-gradient(0deg, rgba(33, 150, 243, 0.1), rgba(33, 150, 243, 0.1)), #FFFFFF;
        height: 25px;
        p.data-text-chip{
          margin: 0;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 18px;
          color: #2196F3;
        }
      }
    }
    .position-details-header--actions{
      .v-btn {
        width: 81px;
        height: 32px;
      }
    }
  }
  .content-position-details--body{
    .position-details-body--header{
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      h5{
        margin-bottom: 16px;
      }
      .v-btn {
        height: 32px;
      }
      .position-details-body--avatar{
        display: flex;
        justify-content: center;
        align-items: center;
        .avatar--icon {
          width: 20px;
          height: 20px;
          border-radius: 10px;
        }
        p{
          font-weight: 600;
          margin: 0;
        }
      }
      .position-details-body--main{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .position-details-body--info{
          display: flex;
          align-items: center;
          .position-details-item{
            display: flex;
            .v-icon{
              font-size: 18px;
            }
          }
        }
        .upper-actions-details-body{
          display: flex;
          align-items: center;
        }
      }
    }
    .position-details-body--description{
      margin-top: 40px;
      margin-bottom: 80px;
      .position-details-body-title{
        font-weight: 800;
        font-size: 18px;
      }
    }
    .position-details-body--requirements{
      p{
        font-weight: 800;
        font-size: 18px;
        margin-bottom: 24px;
      }
      .position-details-requirements{
        margin-bottom: 24px;
        p{
          font-weight: 700;
          font-size: 16px;
            margin-bottom: 12px;
        }
        .position-details-requirements--tags {
          display: flex;
          gap: 5px;
          div {
            border: 1px solid rgba(0, 0, 0, 0.06);
            font-size: 14px;
            color: rgba(0, 0, 0, 0.8);
            padding: 4px 16px;
            border-radius: 16px;
            background-color: white;
          }
        }
      }
    }
    .position-details-body--files{
      margin-bottom: 80px;
      p{
        font-weight: 800;
        font-size: 18px;
        margin-bottom: 12px;
      }
    }
    .position-details-body--features{
      display: flex;
      justify-content: space-evenly;
      margin-top: 80px;
      background-color: #FFFFFF;
      padding: 18px;
      border: 1px solid rgba(0, 0, 0, 0.06);
      p{
        font-weight: 700;
        font-size: 18px;
      }
      .position-details-features--item{
        display: flex;
        flex-direction: column;
        align-items: center;
        .position-details-features-title{
          font-family: 'Lato';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #A0A0A0;
          margin-bottom: 16px;
          text-transform: uppercase;
          margin-bottom: 4px;
        }
        div{
          display: flex;
          align-items: center;
          .v-icon{
            font-size: 14px;
          }
        }
      }
    }
    .position-details-body--footer{
      display: flex;
      justify-content: center;
      margin: 80px 0;
    }
    .position-details-requirements-files {
      .v-btn {
        letter-spacing: normal;
        font-size: 16px;
      }
    }
  }
}
</style>
